import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebase/firebase";
import toast from "react-hot-toast";
import { useRef } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const EditExpertModal = ({ open, user, expert }) => {
  const { editExpert, setEditExpert } = open;
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [account, setAccount] = useState("Starter");
  const {
    losses: exloss,
    name: exName,
    picture: exPic,
    profitShare: exProfit,
    winRate: exRate,
    wins: exWins,
  } = expert;

  const [losses, setLosses] = useState(exloss);
  const [name, setName] = useState(exName);
  const [profitShare, setProfitShare] = useState(exProfit);
  const [picture, setPicture] = useState(exPic);
  const [winRate, setWinRate] = useState(exRate);
  const [wins, setWins] = useState(exWins);
  const [newImage, setNewImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const imageUpload = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [checkmark, setCheckMark] = useState(null);

  function handleLosses(e) {
    const value = e.target.value;

    if (value) {
      setLosses(value);
    } else {
      setLosses(losses);
    }
  }

  function handleName(e) {
    const value = e.target.value;

    if (value) {
      setName(value);
    } else {
      setName(name);
    }
  }

  function handleProfitShare(e) {
    const value = e.target.value;

    if (value) {
      setProfitShare(value);
    } else {
      setProfitShare(profitShare);
    }
  }

  function handleWinRate(e) {
    const value = e.target.value;

    if (value) {
      setWinRate(value);
    } else {
      setWinRate(winRate);
    }
  }

  function handleWins(e) {
    const value = e.target.value;

    if (value) {
      setWins(value);
    } else {
      setWins(wins);
    }
  }

  function handleImage() {
    imageUpload.current.click();
  }

  function handleImageClick(e) {
    const value = e.target.files[0];
    console.log("from ref", imageUpload.current.files[0]);

    if (value) {
      setIsUploading(true);
      const image = URL.createObjectURL(e.target.files[0]);
      const name = value.name + new Date();
      setNewImage(image);
      uploadPicture(value, name);
    }
    URL.revokeObjectURL(e.target.files[0]);
  }

  async function uploadPicture(value, name) {
    const storageRef = ref(storage, name);
    const uploadTask = uploadBytesResumable(storageRef, value);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log(error);
        toast.error("Please try again later");
        setIsUploading(false);
        setNewImage(picture);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setExpertPicture(downloadURL);
        });
      }
    );
  }

  async function setExpertPicture(newURL) {
    const key = `${name}.picture`;
    const q = doc(db, "admin", "experts");
    try {
      await updateDoc(q, {
        [key]: newURL,
      });
      toast.success("Picture updated.");
      setIsUploading(false);
    } catch (error) {
      console.log(error);
      toast.error("Could not update. Please try again later");
      setIsUploading(false);
    }
  }

  async function handleEdit() {
    setIsEditing(true);

    const namekey = `${exName}.name`;
    const losseskey = `${exName}.losses`;
    const profitsharekey = `${exName}.profitShare`;
    const winratekey = `${exName}.winRate`;
    const winskey = `${exName}.wins`;
    const check = `${exName}.checkmark`;

    const q = doc(db, "admin", "experts");
    try {
      await updateDoc(q, {
        [namekey]: name,
        [losseskey]: losses,
        [profitsharekey]: profitShare,
        [winratekey]: winRate,
        [winskey]: wins,
        [check]: checkmark,
      });
      toast.success("Updated successfully.");
      setIsEditing(false);
    } catch (error) {
      console.log(error);
      toast.error("Could not update. Please try again later");
      setIsEditing(false);
    }
  }

  async function handleDelete() {
    setIsDeleting(true);
    const exRef = doc(db, "admin", "experts");
    await updateDoc(exRef, {
      [exName]: deleteField(),
    })
      .then(() => {
        toast.success(
          "Deleted successfully. The changes will update on next refresh."
        );
        setIsDeleting(false);
        setEditExpert(false);
      })
      .catch((error) => {
        toast.error("Failed to delete. Please try again later.");
        setIsDeleting(false);
      });
  }

  return (
    <Modal
      open={editExpert}
      onClose={() => setEditExpert(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Edit Expert</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setEditExpert(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <div>
            <label className="block text-sm font-medium text-white">
              Photo
            </label>
            <div className="mt-1 flex items-center">
              <img
                src={newImage ? newImage : picture}
                alt="profile"
                className="inline-block h-12 w-12 rounded-full overflow-hidden"
              />

              <button
                type="button"
                disabled={isUploading}
                onClick={handleImage}
                className="ml-5 bg-[#1199fa] py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white"
              >
                {isUploading && (
                  <span className="flex w-full">
                    <img
                      src="/svg-loaders/tail-spin.svg"
                      alt="loading"
                      width={12}
                      height={12}
                    />
                  </span>
                )}
                {!isUploading && "Change"}
              </button>

              <input
                type="file"
                onChange={handleImageClick}
                ref={imageUpload}
                style={{
                  opacity: "0",
                  pointerEvents: "none",
                  userSelect: "none",
                  position: "absolute",
                }}
              />
            </div>
          </div>

          <div className="addcon" style={{ color: "#a3a3a3" }}>
            <label htmlFor="new">Expert Name (Doesn't Change)</label>
            <div className="add">
              <input
                onChange={(e) => handleName(e)}
                placeholder={name}
                defaultValue={name}
                disabled
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Losses</label>
            <div className="add">
              <input
                onChange={(e) => handleLosses(e)}
                placeholder={losses}
                defaultValue={losses}
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Profit Share</label>
            <div className="add">
              <input
                onChange={(e) => handleProfitShare(e)}
                placeholder={profitShare}
                defaultValue={profitShare}
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Win Rate</label>
            <div className="add">
              <input
                onChange={(e) => handleWinRate(e)}
                placeholder={winRate}
                defaultValue={winRate}
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Wins</label>
            <div className="add">
              <input
                onChange={(e) => handleWins(e)}
                placeholder={wins}
                defaultValue={wins}
              />
            </div>
          </div>

          <div
            className="selectWrap"
            style={{
              boxSizing: "border-box",
            }}
          >
            <label htmlFor="asset">Checkmark</label>
            <div
              className="selectBox"
              style={{
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div className="selectTop">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                    gap: "0.5rem",
                    boxSizing: "border-box",
                    padding: "1rem",
                  }}
                >
                  <select
                    name="asset"
                    style={{
                      padding: "0",
                      width: "100%",
                      boxSizing: "border-box",
                      display: "flex",
                    }}
                    onChange={(e) => setCheckMark(e.target.value)}
                  >
                    <option value="None">None</option>
                    <option value="Gold">Gold</option>
                    <option value="Blue">Blue</option>
                  </select>
                </div>
                <img
                  src="/extraicons/arrowdown.svg"
                  alt="select"
                  className="dropDownIcon"
                />
              </div>
            </div>
          </div>

          <div className="flex space-x-4">
            <button
              className={
                !name ||
                !losses ||
                !picture ||
                !profitShare ||
                !winRate ||
                !wins ||
                isUploading ||
                isEditing ||
                isDeleting
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                !name ||
                !losses ||
                !picture ||
                !profitShare ||
                !winRate ||
                !wins ||
                isUploading ||
                isEditing ||
                isDeleting
              }
              onClick={() => handleEdit()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isEditing ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Save</p>
              )}
            </button>

            <button
              className={
                !name ||
                !losses ||
                !picture ||
                !profitShare ||
                !winRate ||
                !wins ||
                isUploading ||
                isEditing
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                !name ||
                !losses ||
                !picture ||
                !profitShare ||
                !winRate ||
                !wins ||
                isUploading ||
                isEditing
              }
              onClick={() => handleDelete()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
                backgroundColor: "#E64B60",
              }}
            >
              {isDeleting ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Delete</p>
              )}
            </button>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default EditExpertModal;
