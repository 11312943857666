import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { userContext } from "../../context/userContext";
import { auth, db } from "../../firebase/firebase";
import { OutlinedBox } from "../../styles";

const Crypto = ({ action, assets, user, open }) => {
  // const {active, setActive} = type;

  const { fixTrade, setFixTrade } = open;

  const { accounts, currentPrices, currentAccount } = useContext(userContext);
  const [asset, setAsset] = useState(assets[0].symbol);
  const [assetPrice, setAssetPrice] = useState(
    currentPrices[asset.slice(0, -3)]
  );
  const [cryptoAccounts, setCryptoAccounts] = useState([]);
  const [fiatAccount, setFiatAccount] = useState([]);
  const [balance, setBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [profit, setProfit] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [id, setID] = useState("");
  const [liveAccount, setLiveAccount] = useState([]);
  const [practiceAccount, setPracticeAccount] = useState([]);
  // const [accounts, setAccounts] = useState([]);
  const [time, setTime] = useState(2);

  const [profitType, setProfitType] = useState("profit");

  const [result, setResult] = useState("Profit");

  const [entry, setEntry] = useState(assetPrice);

  const [extraOptions, setExtraOptions] = useState(false);

  const amountRef = useRef();
  const profitRef = useRef();

  const cryptoassets = [
    // { name: "1nch Protocol", symbol: "1INCHUSD" },

    { name: "Aave", symbol: "AAVEUSD" },
    { name: "Cardano", symbol: "ADAUSD" },
    { name: "Algorand", symbol: "ALGOUSD" },
    { name: "Anchor Protocol", symbol: "ANCUSD" },
    { name: "Apecoin", symbol: "APEUSD" },
    { name: "Aurora", symbol: "AURORAUSD" },
    { name: "Avalanche", symbol: "AVAXUSD" },
    { name: "Axie Infinity", symbol: "AXSUSD" },

    { name: "Bitcoin Cash", symbol: "BCHUSD" },
    { name: "Boring DAO", symbol: "BORINGUSD" },
    { name: "Bitcoin", symbol: "BTCUSD" },
    { name: "Bitcoin Gold", symbol: "BTGUSD" },

    { name: "Comdex", symbol: "CMDXUSD" },
    { name: "Cronos", symbol: "CROUSD" },

    { name: "Dai", symbol: "DAIUSD" },
    { name: "Dao Maker", symbol: "DAOUSD" },
    { name: "Dash", symbol: "DASHUSD" },
    { name: "Defi Land", symbol: "DFLUSD" },
    { name: "Polkadot", symbol: "DOTUSD" },
    { name: "Dogecoin", symbol: "DOGEUSD" },

    { name: "Evmos", symbol: "EVMOSUSD" },
    { name: "Ethereum Classic", symbol: "ETCUSD" },
    { name: "Ethereum", symbol: "ETHUSD" },

    { name: "Stepn", symbol: "GMTUSD" },
    { name: "Gochain", symbol: "GOUSD" },
    { name: "The Graph", symbol: "GRTUSD" },
    { name: "Gate", symbol: "GTUSD" },

    { name: "ChainLink", symbol: "LINKUSD" },
    { name: "Link", symbol: "LNUSD" },
    { name: "Litecoin", symbol: "LTCUSD" },

    { name: "Decentraland", symbol: "MANAUSD" },
    { name: "Matic", symbol: "MATICUSD" },

    { name: "Nexo", symbol: "NEXOUSD" },
    { name: "Energi", symbol: "NRGUSD" },

    { name: "Origin Protocol", symbol: "OGNUSD" },
    { name: "Okb", symbol: "OKBUSD" },
    { name: "Optimism", symbol: "OPUSD" },
    { name: "Orion Protocol", symbol: "ORNUSD" },

    { name: "Raini", symbol: "RAINIUSD" },
    { name: "Rarible", symbol: "RARIUSD" },

    { name: "Safepal", symbol: "SFPUSD" },
    { name: "Shiba Inu", symbol: "SHIBUSD" },
    { name: "Step Finance", symbol: "STEPUSD" },
    { name: "Sushi", symbol: "SUSHIUSD" },

    { name: "Wonderland", symbol: "TIMEUSD" },
    { name: "Telos", symbol: "TLOSUSD" },
    { name: "Tron", symbol: "TRXUSD" },

    { name: "Solana", symbol: "SOLUSD" },

    { name: "Uniswap", symbol: "UNIUSD" },
    { name: "USD Coin", symbol: "USDCUSD" },
    { name: "Tether", symbol: "USDTUSD" },

    { name: "VeChain", symbol: "VETUSD" },

    { name: "Wing Finance", symbol: "WINGUSD" },

    { name: "Chain", symbol: "XCNUSD" },
    { name: "Stellar", symbol: "XLMUSD" },
    { name: "Monero", symbol: "XMRUSD" },
    { name: "Proton", symbol: "XPRUSD" },
    { name: "Tezos", symbol: "XTZUSD" },

    { name: "Zcash", symbol: "ZECUSD" },
    { name: "Zignaly", symbol: "ZIGUSD" },
  ];

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      if (live && practice) {
        setLiveAccount(live);
        setPracticeAccount(practice);
      }
    } else {
      return;
    }
  }, [accounts]);

  useEffect(() => {
    if (currentAccount && liveAccount && practiceAccount) {
      switch (currentAccount) {
        case "live":
          setCryptoAccounts(liveAccount.Crypto);
          setFiatAccount(liveAccount.Fiat);
          break;
        case "practice":
          setCryptoAccounts(practiceAccount.Crypto);
          setFiatAccount(practiceAccount.Fiat);
          break;
        default:
          break;
      }
    }
  }, [currentAccount, liveAccount, practiceAccount]);

  useEffect(() => {
    if (fiatAccount && cryptoAccounts) {
      if (action === "buy") {
        setBalance(fiatAccount.value);
      }

      if (action === "sell") {
        setBalance(cryptoAccounts[asset.slice(0, -3)].value);
      }
    }
  }, [fiatAccount, action, asset, balance, amount, cryptoAccounts]);

  function handleAssetChange(e) {
    const value = e.target.value;

    assets.forEach((acc) => {
      if (acc.name === value) {
        setAsset(acc.symbol);
      }
    });
  }

  useEffect(() => {
    if (asset) {
      setAssetPrice(currentPrices[asset.slice(0, -3)]);
      setEntry(currentPrices[asset.slice(0, -3)]);
    }
  }, [asset, currentPrices]);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
  }

  function handleProfit(e) {
    const value = e.target.value;
    if (value > 0) {
      setProfit(value);
    } else {
      setProfit("");
    }
  }

  function handleTimeInForce(e) {
    const value = e.target.value;
    setTime(Number(value));
  }

  function handleResult(e) {
    const value = e.target.value;
    setResult(value);
  }

  function reset() {
    setAmount("");
    if (amountRef) {
      amountRef.current.value = "";
    }
  }

  const handleTrade = () => {
    setIsSubmitting(true);
    submitUp();
  };

  async function submitUp() {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const converted = Number(
      amount / currentPrices[asset.slice(0, -3)]
    ).toFixed(2);

    const str =
      user.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "open",
      fixed: true,
      result: profitType.charAt(0).toUpperCase() + profitType.substring(1),
      profit: profit ? profit : 0,
      profitType,
      converted,
      fixType: action,
      duration: time,
      user,
      amount,
      asset,
      price: assetPrice,
      action: action === "up" ? "Buy" : "Sell",
      details: [
        {
          name: "Action",
          value: action === "up" ? "Buy" : "Sell",
        },
        {
          name: "Entry Price",
          value: entry,
        },
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Amount",
          value: amount,
        },
      ],
    })
      .then(() => {
        // incrementCrypto(converted)
        setIsSubmitting(false);
        setFixTrade(false);
        const details = {
          fixed: true,
          profit: profit ? profit : 0,
          profitType,
          action: action === "up" ? "Buy" : "Sell",
          account: "live",
          entry: assetPrice,
          asset,
          time,
          amount,
          symbol: asset,
        };
        postTrade(user, str, details);
        // console.log('profits', profit)
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Trade Could Not Complete.");
        setIsSubmitting(false);
      });
  }

  async function postTrade(user, str, details) {
    const url =
      "https://fin-http-production.up.railway.app/bullishruns/crypto";

    const base = {
      user,
      ref: str,
      details,
    };

    const config = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          toast.success("Trade sucessfully placed.");
          setIsSubmitting(false);
          setFixTrade(false);
          // reset();
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Trade Could Not Complete.");
        setIsSubmitting(false);
      });
  }

  function handleTypeChange(e) {
    const { value } = e.target;
    setProfit(true);
    setProfitType(value);
  }

  return (
    <Wrapper>
      <div className="selectWrap">
        <label htmlFor="pair">Asset</label>
        <div className="selectBox">
          <div className="selectTop">
            {/* <span> */}
            <select name="asset" onChange={handleAssetChange}>
              {assets.map((ass) => (
                <option key={ass.symbol}>{ass.name}</option>
              ))}
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
            {/* </span> */}
          </div>
          {/* 
          <div className="captions">
            <span></span>

            {assetPrice && (
              <p className="balance">
                Current Price{" "}
                <span className="green">~${Number(assetPrice).toFixed(2)}</span>
              </p>
            )}
          </div> */}
        </div>
      </div>

      <div className="selectWrap">
        <label htmlFor="pair">Type</label>
        <div className="selectBox">
          <div className="selectTop">
            <select name="asset" onChange={handleTypeChange}>
              <option value="profits">Profit</option>
              <option value="loss">Loss</option>
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>

      <div className="amWrap">
        <label htmlFor="amount">Amount</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder="1000"
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleAmount}
              ref={amountRef}
            />
          </div>
        </div>
      </div>

      {/* <div className="amWrap">
        <label htmlFor="amount">Profit</label>
        <div className="amBx">
          <div className="amTop">
            <input
              placeholder="1000"
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleProfit}
              ref={profitRef}
            />
          </div>
          
        </div>
      </div>  */}

      {/* <div
        className="clickable"
        style={{
          display: "flex",
          placeSelf: "end",
          alignItems: "center",
          gap: "0.3rem",
        }}
      >
        <input
          type="checkbox"
          style={{ accentColor: "#1199fa" }}
          onChange={() => setExtraOptions(!extraOptions)}
        />
        Extra
      </div> */}

      <div className="selectWrap">
        <label htmlFor="timeinforce">Duration(minutes)</label>
        <div className="selectBox">
          <div className="selectTop">
            <select name="timeinforce" onChange={handleTimeInForce}>
              <option>2</option>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>30</option>
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>

      {/* <OutlinedBox style={{ padding: "0.5rem", margin: "0", width: "100%" }}>
        <span
          className="infot"
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "360px",
          }}
        >
          { "This trade will close in " + time + " minutes."}
        </span>
      </OutlinedBox> */}

      <button
        className={
          !amount || !asset || !assetPrice || !time
            ? "button disabled"
            : "button submit"
        }
        disabled={!amount || !asset || !assetPrice || !time}
        onClick={handleTrade}
      >
        {isSubmitting ? (
          <img
            src="/svg-loaders/tail-spin.svg"
            alt="loading"
            width={24}
            height={24}
          />
        ) : (
          <span className="capitalize" style={{ alignSelf: "center" }}>
            {"Place Trade"}
          </span>
        )}
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 0.8rem;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

export default Crypto;
