import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebase/firebase";
import toast from "react-hot-toast";
import { useRef } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const AddExpertModal = ({ open }) => {
  const { addExpert, setAddExpert } = open;
  const [losses, setLosses] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [profitShare, setProfitShare] = useState(undefined);
  const [picture, setPicture] = useState(undefined);
  const [winRate, setWinRate] = useState(undefined);
  const [wins, setWins] = useState(undefined);
  const [newImage, setNewImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const imageUpload = useRef();
  const [isAdding, setIsAdding] = useState(false);
  const [imageURL, setImageURL] = useState(undefined);
  const [checkmark, setCheckMark] = useState(null);

  function handleLosses(e) {
    const value = e.target.value;

    if (value) {
      setLosses(value);
    } else {
      setLosses("");
    }
  }

  function handleName(e) {
    const value = e.target.value;

    if (value) {
      setName(value);
    } else {
      setName("");
    }
  }

  function handleProfitShare(e) {
    const value = e.target.value;

    if (value) {
      setProfitShare(value);
    } else {
      setProfitShare("");
    }
  }

  function handleWinRate(e) {
    const value = e.target.value;

    if (value) {
      setWinRate(value);
    } else {
      setWinRate("");
    }
  }

  function handleWins(e) {
    const value = e.target.value;

    if (value) {
      setWins(value);
    } else {
      setWins("");
    }
  }

  function handleImage() {
    imageUpload.current.click();
  }

  function handleImageClick(e) {
    const value = e.target.files[0];
    console.log("from ref", imageUpload.current.files[0]);

    if (value) {
      setIsUploading(true);
      const image = URL.createObjectURL(e.target.files[0]);
      const name = value.name + new Date();
      setNewImage(image);
      setPicture(image);
      uploadPicture(image, value, name);
    }
    URL.revokeObjectURL(e.target.files[0]);
  }

  async function uploadPicture(image, value, name) {
    const storageRef = ref(storage, name);
    const uploadTask = uploadBytesResumable(storageRef, value);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log(error);
        toast.error("Please try again later");
        setIsUploading(false);
        setNewImage(image);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setExpertPicture(downloadURL);
          setImageURL(downloadURL);
        });
      }
    );
  }

  async function setExpertPicture(newURL) {
    const key = `${name}.picture`;
    const q = doc(db, "admin", "experts");
    try {
      await updateDoc(q, {
        [key]: newURL,
      });
      toast.success("Picture updated.");
      setIsUploading(false);
    } catch (error) {
      console.log(error);
      toast.error("Could not update. Please try again later");
      setIsUploading(false);
    }
  }

  async function handleAdd() {
    setIsAdding(true);

    const namekey = `${name}.name`;
    const losseskey = `${name}.losses`;
    const profitsharekey = `${name}.profitShare`;
    const winratekey = `${name}.winRate`;
    const winskey = `${name}.wins`;
    const subscriberslistkey = `${name}.subscriberList`;
    const picturekey = `${name}.picture`;
    const check = `${name}.checkmark`;

    const q = doc(db, "admin", "experts");
    try {
      await updateDoc(q, {
        [name]: {
          name,
          losses,
          profitShare,
          winRate,
          wins,
          picture: imageURL,
          checkmark: checkmark,
          subscriberList: [],
        },
      });
      toast.success("Added successfully.");
      setIsAdding(false);
    } catch (error) {
      console.log(error);
      toast.error("Could not add. Please try again later");
      setIsAdding(false);
    }
  }

  async function handleCancel() {
    setAddExpert(false);
  }

  return (
    <Modal
      open={addExpert}
      onClose={() => setAddExpert(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Add Expert</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setAddExpert(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <div>
            <label className="block text-sm font-medium text-white">
              Photo
            </label>
            <div className="mt-1 flex items-center">
              <img
                src={
                  newImage
                    ? newImage
                    : picture ||
                      "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg"
                }
                alt="profile"
                className="inline-block h-12 w-12 rounded-full overflow-hidden"
              />

              <button
                type="button"
                disabled={isUploading || !name}
                onClick={handleImage}
                className={
                  !name
                    ? "ml-5 bg-gray-800 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white"
                    : "ml-5 bg-[#1199fa] py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white"
                }
              >
                {isUploading && (
                  <span className="flex w-full">
                    <img
                      src="/svg-loaders/tail-spin.svg"
                      alt="loading"
                      width={12}
                      height={12}
                    />
                  </span>
                )}
                {!isUploading && !name && "Add name to change"}
                {!isUploading && name && "Change"}
              </button>

              <input
                type="file"
                onChange={handleImageClick}
                ref={imageUpload}
                style={{
                  opacity: "0",
                  pointerEvents: "none",
                  userSelect: "none",
                  position: "absolute",
                }}
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Expert Name</label>
            <div className="add">
              <input
                onChange={(e) => handleName(e)}
                placeholder={name}
                defaultValue={name}
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Losses</label>
            <div className="add">
              <input
                onChange={(e) => handleLosses(e)}
                placeholder={losses}
                defaultValue={losses}
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Profit Share</label>
            <div className="add">
              <input
                onChange={(e) => handleProfitShare(e)}
                placeholder={profitShare}
                defaultValue={profitShare}
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Win Rate</label>
            <div className="add">
              <input
                onChange={(e) => handleWinRate(e)}
                placeholder={winRate}
                defaultValue={winRate}
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Wins</label>
            <div className="add">
              <input
                onChange={(e) => handleWins(e)}
                placeholder={wins}
                defaultValue={wins}
              />
            </div>
          </div>

          <div
            className="selectWrap"
            style={{
              boxSizing: "border-box",
            }}
          >
            <label htmlFor="asset">Checkmark</label>
            <div
              className="selectBox"
              style={{
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div className="selectTop">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                    gap: "0.5rem",
                    boxSizing: "border-box",
                    padding: "1rem",
                  }}
                >
                  <select
                    name="asset"
                    style={{
                      padding: "0",
                      width: "100%",
                      boxSizing: "border-box",
                      display: "flex",
                    }}
                    onChange={(e) => setCheckMark(e.target.value)}
                  >
                    <option value="None">None</option>
                    <option value="Gold">Gold</option>
                    <option value="Blue">Blue</option>
                  </select>
                </div>
                <img
                  src="/extraicons/arrowdown.svg"
                  alt="select"
                  className="dropDownIcon"
                />
              </div>
            </div>
          </div>

          <div className="flex space-x-4">
            <button
              className={
                !name ||
                !losses ||
                !picture ||
                !profitShare ||
                !winRate ||
                !wins ||
                isUploading ||
                isAdding
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                !name ||
                !losses ||
                !picture ||
                !profitShare ||
                !winRate ||
                !wins ||
                isUploading ||
                isAdding
              }
              onClick={() => handleAdd()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isAdding ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Add</p>
              )}
            </button>

            <button
              onClick={() => handleCancel()}
              // style={{
              //   display: "grid",
              //   textAlign: "left",
              //   placeContent: "center",
              //   backgroundColor: "#E64B60"
              // }}
              style={{
                width: "100%",
                backgroundColor: "#E64B60",
                color: "white",
                textTransform: "uppercase",
                height: "44px",
                display: "grid",
                placeItems: "center",
                fontWeight: "600",
                borderRadius: "8px",
              }}
            >
              Close
            </button>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default AddExpertModal;
