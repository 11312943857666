import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCG-B3w1Voi7-YEXgBCKgZU42xBsINme80",
  authDomain: "bullish-runs.firebaseapp.com",
  projectId: "bullish-runs",
  storageBucket: "bullish-runs.appspot.com",
  messagingSenderId: "130107873813",
  appId: "1:130107873813:web:3a70af28ed9345a59b4c1c",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBGq25Hh0nt1zy-G4L32-hYypt6LDS9ULc",
//   authDomain: "finance-test-5f328.firebaseapp.com",
//   projectId: "finance-test-5f328",
//   storageBucket: "finance-test-5f328.appspot.com",
//   messagingSenderId: "261981000578",
//   appId: "1:261981000578:web:ee913ada0c961a2803759b",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
